<template>
  <v-layout>
    <v-app-bar app height="124" class="align-start elevation-1">
      <v-col cols="12" class="py-0">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 25px"></div>
          </v-col>
          <v-col cols="auto">
            <v-toolbar-title>
              {{ $route.name }}
            </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-app-bar>

    <v-container fluid class="px-0 pt-5">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-text class="px-2 subtitle-2">
                <v-row dense class="align-center justify-center">
                  <v-col cols="12">
                    <v-card tile flat outlined class="flex-grow-1">
                      <v-card-title>
                        <span>
                          Stammdaten
                        </span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="text--primary">
                        <v-list>
                          <v-list-item
                            ><v-list-item-title>Mitarbeiter{{ " (" + nullth.Mitarbeiter + ")" }}</v-list-item-title>
                            <v-list-item-icon>
                              <v-btn small outlined icon to="/wws/settings/masterdata/coworkers"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-list-item-icon>
                          </v-list-item>
                          <v-list-item
                            ><v-list-item-title>Abteilungen{{ " (" + first.Abteilungen + ")" }}</v-list-item-title>
                            <v-list-item-icon>
                              <v-btn small outlined icon to="/wws/settings/masterdata/departments"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-list-item-icon>
                          </v-list-item>
                          <v-list-item
                            ><v-list-item-title>Artikelgruppen{{ " (" + second.Artikelgruppen + ")" }}</v-list-item-title>
                            <v-list-item-icon>
                              <v-btn small outlined icon to="/wws/settings/masterdata/groups"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-list-item-icon>
                          </v-list-item>
                          <v-list-item
                            ><v-list-item-title>Artikeluntergruppen{{ " (" + third.Artikeluntergruppen + ")" }}</v-list-item-title>
                            <v-list-item-icon>
                              <v-btn small outlined icon to="/wws/settings/masterdata/subgroups"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-list-item-icon>
                          </v-list-item>

                          <v-list-group sub-group no-action class="text--primary">
                            <template v-slot:activator>
                              <v-list-item-title>Artikeleigenschaften</v-list-item-title>
                            </template>

                            <v-list-item v-for="([title, to, icon, amount], i) in features" :key="i" link>
                              <v-list-item-title>{{ title }}{{ " (" + amount + ")" }}</v-list-item-title>

                              <v-list-item-icon>
                                <v-btn small outlined icon :to="to"
                                  ><v-icon> {{ icon }}</v-icon></v-btn
                                >
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list-group>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
import MasterdataOverviewAppBar from "../../components/appbars/MasterdataOverviewAppBar.vue";

export default {
  components: {
    MasterdataOverviewAppBar,
  },

  // created() {
  //   this.initialize();
  // },

  mounted() {
    this.initialize();
  },

  data() {
    return {
      nullth: "",
      first: "",
      second: "",
      third: "",
      forth: "",
      fifth: "",
      sixth: "",
      seventh: "",
      features: [
        ["Farben", "/wws/settings/masterdata/colors", "mdi-pencil"],
        ["Stoffe", "/wws/settings/masterdata/fabrics", "mdi-pencil"],
        ["Schnitte", "/wws/settings/masterdata/cuts", "mdi-pencil"],
        ["Einheiten", "/wws/settings/masterdata/units", "mdi-pencil"],
      ],
    };
  },

  computed: {},

  methods: {
    async initialize() {
      const response = await fetch(`/api/settings/masterdata`);
      const json = await response.json();
      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.features[0].push(this.forth.Artikelfarben);
      this.features[1].push(this.fifth.Artikelstoffe);
      this.features[2].push(this.sixth.Artikelschnitte);
      this.features[3].push(this.seventh.Artikeleinheiten);
    },
  },
};
</script>
